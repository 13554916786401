body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;
  background-color: #111111;
  color: #dddddd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: #2798da;
  }
}

.app {
  text-align: center;
}

// Leftover from template
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
