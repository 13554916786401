.poster-game-container {
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  button {
    margin: 20px 0 80px 0;
    padding: 8px 12px;
    font-size: 15px;
    font-weight: bold;
  }

  input {
    margin-top: 50px;
    padding: 8px 12px;
    font-size: 15px;
    font-weight: bold;
    width: 300px;
  }

  select {
    margin-top: 20px;
    padding: 8px 12px;
    font-size: 15px;
    font-weight: bold;
    width: 400px;
  }

  .poster {
    width: 400px;
    height: auto;

    &.small {
      width: 300px;
    }
  }

  h2, h3 {
    margin-top: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    margin: 10px 0;
  }

  a {
    margin-top: 20px;
    font-size: 20px;
  }

  .percent {
    margin-left: 25px;
    font-size: 35px;
    color: #aa0000;

    &.green {
      color: #368512;
    }
    &.yellow {
      color: #a8a603;
    }
    &.orange {
      color: #aa4b0c;
    }
    &.red {
      color: #aa0000;
    }
  }

  h1 {
    .percent {
      font-size: 45px;
    }
  }

  .test-icon {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }

  .left-bound {
    text-align: left;
  }
}