.four-oh-four-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 500px;
    padding: 15px 20px;
    background-color: #181818;
    border-radius: 15px;
    opacity: 0.7;
    z-index: 1;

    .title {
      font-family: "Bebas Neue", sans-serif;
      text-align: center;
      font-size: 40px;
      font-weight: bold;
    }

    .description {
      text-align: center;
      font-size: 20px;
    }

    .home-link {
      text-align: center;
      font-size: 20px;
      margin: 10px 0 15px 0;
      text-decoration: none;
      font-weight: 600;
    }

    .error {
      text-align: center;
      font-size: 15px;
      color: #888888;
    }
  }

  .background-video {
    position: absolute;
    bottom: 0;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    z-index: 0;
  }
}