.ssia-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: serif;

  .banner {
    height: auto;
    width: 400px;
  }

  .cover-image {
    height: 400px;
    width: 400px;
  }

  .text-block {
    display: block;
    width: 400px;
    margin-bottom: 20px;

    &.disclaimer {
      margin-top: 50px;
    }

    &.lol {
      margin: 4000px 0 80px 0;
    }
  }
}